import React from "react"
import Layout from "../../layouts/default"

import AudioPlayer from "../../components/audio-player"
import Breadcrumb from "../../components/breadcrumb"
import Constrain from "../../components/constrain"
import PageTitle from "../../components/page-title"
import Seo from "../../components/seo"
import Stack from "../../components/stack"

const Katalog13 = () => {
  return (
    <Layout backdrop="aussen">
      <Seo title="Johann Georg Fischer: Der verhängnisvolle Tanz" />
      <Stack space={[12, 24]}>
        <Stack>
          <Breadcrumb
            items={[
              {
                title: "Johann Georg Fischer: Der verhängnisvolle Tanz",
                link: "/k/13",
              },
            ]}
          />
          <PageTitle>Johann Georg Fischer: Der verhängnisvolle Tanz</PageTitle>
          <Constrain>
            <AudioPlayer src="katalog/13-johann-georg-fischer" />
          </Constrain>
        </Stack>
      </Stack>
    </Layout>
  )
}

export default Katalog13
